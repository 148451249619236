import { injectable } from 'inversify';
import { LanguageInterface } from 'src/repository/interfaces/Language/LanguageInterface';
import { api } from './_index';

@injectable()
export default class LanguageApiService implements LanguageInterface {
  all(language): Promise<any> {
    return api
      .get(language + '/languages')
      .then((response) => response.data)
      .catch((error) => error.response);
  }
}
