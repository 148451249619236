// import { Container } from './styles';

// import { Link } from "react-router-dom";

export default function LauchTemplate({ children }) {
  return (
    <div>
      {/* <div>
                <ul>
                    <li><Link to={'/lauch'}>lauch</Link></li>
                    <li><Link to={'/'}>home</Link></li>
                </ul>
            </div> */}
      {children}
    </div>
  );
}
