import React from 'react';
import AppTemplate from './AppTemplate';
import LauchTemplate from './LauchTemplate';

const layouts = {
  LauchTemplate: LauchTemplate,
  AppTemplate: AppTemplate,
};

export type layout = 'LauchTemplate' | 'AppTemplate';

export interface LayoutProps {
  layout: layout;
  children: JSX.Element;
  title?: string;
}

export const Layout = ({ children, layout }: LayoutProps) => {
  let Component = layouts[layout] || React.Fragment;
  return <Component>{children}</Component>;
};
