import { Paragraph } from '@hexa-ui/components';
import { injectable } from 'inversify';
import { LaunchInterface } from 'src/repository/interfaces/Launch/LaunchInterface';
import { Launch } from 'src/repository/types/Launch';
import { api } from './_index';

@injectable()
export default class LaunchApiService implements LaunchInterface {
  /**
   * Display a listing of the resource.
   *
   */
  all(filter): Promise<Launch[]> {
    return api
      .get('/banner/list?' + new URLSearchParams(filter).toString())
      .then((response) => {
        let value = response.data.launchers;

        delete value['total'];
        let result = Object.values(value).map((launch: Launch) => ({
          ...launch,
          field_title: (
            <div>
              <p style={{ margin: 0 }}>{launch.title}</p>
              <p style={{ margin: 0, fontSize: '12px' }}>{launch.field_type}</p>
            </div>
          ),
          statuses: (
            <div style={{ display: 'inline-flex', alignItems: 'center', gap: '0.5rem' }}>
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '100%',
                  backgroundColor:
                    launch.status === 'Live'
                      ? 'green'
                      : launch.status === 'Scheduled'
                      ? '#FEA436'
                      : '#545254',
                }}
              />
              <Paragraph>{launch.status}</Paragraph>
            </div>
          ),
          field_launch_date: new Date(Number(launch.field_launch_date) * 1000).toLocaleString(),
          created: new Date(Number(launch.created) * 1000).toLocaleString(),
          updated: (
            <div>
              <p style={{ margin: 0 }}>
                {new Date(Number(launch.updated) * 1000).toLocaleString()}
              </p>
              <p style={{ margin: 0, fontSize: '12px' }}>{launch.email}</p>
            </div>
          ),
        }));
        return result;
      })
      .catch((error) => error.response);
  }

  /**
   * Store a launch.
   */
  store(launch): Promise<any> {
    return api
      .post('/banner/create', launch)
      .then((response) => response)
      .catch((error) => error.response);
  }

  /**
   * Show a launch.
   */
  show(id): Promise<any> {
    return api
      .get('/banner/' + id)
      .then((response) => {
        let value = response.data;
        delete value['total'];
        let result = {
          ...value,
          created: new Date(Number(value.field_launch_date) * 1000).toLocaleString(),
          end: value?.field_launch_end_date ? new Date(Number(value.field_launch_end_date) * 1000).toLocaleString() : '',
          csvLink: value?.field_poc?.csv_url,
          launch_date: new Date(Number(value.field_launch_date) * 1000),
          launch_end_date: value?.field_launch_end_date ? new Date(Number(value.field_launch_end_date) * 1000) : '',
          updated: (
            <div>
              <p style={{ margin: 0 }}>{new Date(Number(value.updated) * 1000).toLocaleString()}</p>
              <p style={{ margin: 0, fontSize: '12px' }}>{value.email}</p>
            </div>
          ),
        };

        return result;
      })
      .catch((error) => error.response);
  }

  /**
   *  Update a launch.
   */
  update(launch, id): Promise<any> {
    return api
      .patch('/banner/' + id, launch)
      .then((response) => response)
      .catch((error) => error.response);
  }

  /**
   *  Delete a launch.
   */
  delete(id): Promise<any> {
    return api
      .delete('/banner/' + id)
      .then((response) => response.data)
      .catch((error) => error.response);
  }

  deleteBanner(launchId, bannerId): Promise<any> {
    return api
      .delete(`/banner/${launchId}?pid=${bannerId}`)
      .then((response) => response)
      .catch((error) => error.response);
  }

  addBanner(banners, launchId): Promise<any> {
    return api
      .post(`/banner/${launchId}/create/item`, banners)
      .then((response) => response)
      .catch((error) => error.response);
  }
}
