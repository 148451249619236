import { injectable } from 'inversify';
import { API_URL } from 'src/constants';
import { FileInterface } from 'src/repository/interfaces/File/FileInterface';
import { api } from './_index';

@injectable()
export default class FileApiService implements FileInterface {
  /**
   * Display a listing of the resource.
   */
  uploadImage(imageData): Promise<any> {
    return api
      .post(
        API_URL + '/upload/paragraph/banner_paragraph/field_image?_format=json',
        imageData,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition':
              'file; filename="' + Math.random().toString(36).slice(2) + '.jpg"',
            Accept: 'application/vnd.api+json',
          },
        }
      )
      .then((response) => response)
      .catch((error) => error.response);
  }

  uploadFile(fileData): Promise<any> {
    return api
      .post(
        API_URL + '/file/upload/node/banner/field_poc_csv?_format=json',
        fileData?.file,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition':
            'file; filename="' + fileData.file.name + '"'
          },
        }
      )
      .then((response) => response)
      .catch((error) => error.response);
  }
}
