import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from 'src/templates';
import { Spinner } from 'supplier-portal-shared-components';
import RouteGuardian from './RouteGuardian';
import _routes from './routes';

const Router = (): JSX.Element => {
  const state = useSelector((state: any) => state);

  return (
    <BrowserRouter>
      <Routes>
        {_routes.map((route, index) => (
          <Route
            path={route.path}
            key={index}
            element={
              <RouteGuardian isPrivate={route.isPrivate} countryStore={state.countryStore}>
                <Layout layout={route.template}>
                  <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
                    <route.element />
                  </Suspense>
                </Layout>
              </RouteGuardian>
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
