import AutoBreadcrumb from '@Components/AutoBreadcrumb/AutoBreadcrumb';
import SelectCountryStore from '@Components/SelectCountryStore';
import { Stack } from '@mui/material';
import React from 'react';


function AppHeader() { 
  return (
    <Stack py={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <AutoBreadcrumb />
      <SelectCountryStore />
    </Stack>
  );
}

export default React.memo(AppHeader);
