import { Container } from 'inversify';
import 'reflect-metadata';
import { CountryInterface } from '../interfaces/Country/CountryInterface';
import { FileInterface } from '../interfaces/File/FileInterface';
import { LanguageInterface } from '../interfaces/Language/LanguageInterface';
import { LaunchInterface } from '../interfaces/Launch/LaunchInterface';
import { LaunchTypeInterface } from '../interfaces/Launch/LaunchTypeInterface';
import CountryApiService from './api/CountryApiService';
import FileApiService from './api/FileApiService';
import LanguageApiService from './api/LanguageApiService';
import LaunchApiService from './api/LaunchApiService';
import LaunchTypeApiService from './api/LaunchTypeApiService';

export const SERVICE_KEYS = {
  COUNTRY_REPOSITORY: Symbol('COUNTRY_REPOSITORY'),
  LAUNCH_REPOSITORY: Symbol('LAUNCH_REPOSITORY'),
  LAUNCH_TYPE_REPOSITORY: Symbol('LAUNCH_TYPE_REPOSITORY'),
  FILE_REPOSITORY: Symbol('FILE_REPOSITORY'),
  LANGUAGE_REPOSITORY: Symbol('LANGUAGE_REPOSITORY'),
};

const _container = new Container();

_container
  .bind<CountryInterface>(SERVICE_KEYS.COUNTRY_REPOSITORY)
  .to(CountryApiService)
  .inSingletonScope();

_container
  .bind<LaunchInterface>(SERVICE_KEYS.LAUNCH_REPOSITORY)
  .to(LaunchApiService)
  .inSingletonScope();

_container
  .bind<LaunchTypeInterface>(SERVICE_KEYS.LAUNCH_TYPE_REPOSITORY)
  .to(LaunchTypeApiService)
  .inSingletonScope();

_container
  .bind<FileInterface>(SERVICE_KEYS.FILE_REPOSITORY)
  .to(FileApiService)
  .inSingletonScope();

_container
  .bind<LanguageInterface>(SERVICE_KEYS.LANGUAGE_REPOSITORY)
  .to(LanguageApiService)
  .inSingletonScope();

export const container = _container;
