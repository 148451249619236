import axios from 'axios';
import { API_URL } from 'src/constants';

export const api = axios.create({
  baseURL: API_URL + '/api',
});
 
api.defaults.headers['Authorization'] = 'Basic YmVlc2xpbmtfYXBpOmJlZXNsaW5rX2FwaUA=';
api.defaults.headers['X-CSRF-Token'] = 'CalxLNl1ENhUDl43bDqLb7HlTO3SdFSXHN1rS4p7r3c';
api.defaults.headers.post['Content-Type'] = 'application/json'; 

api.interceptors.request.use(
  function (config) {
    // console.log('HTTP_REQUEST_INTERCEPTED', config);
    return config;
  },
  function (error) {
    // console.log('HTTP_ERROR_REQUEST_INTERCEPTED:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(null, function (error) {

  return Promise.reject(error);
});
