// import { Container } from './styles';

import AppHeader from '@Components/AppHeader';
import { CreditCard } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import * as Styled from './styles';

// import { Link } from "react-router-dom";

export default function AppTemplate({ children }) {
  useSidebar({
    items: [
      {
        id: '1',
        title: 'BEES Banner',
        icon: () => <CreditCard />,
        path: '/banner-cms',
        onPageChange: () => {},
      },
    ],
    utils: [],
  });
  return (
    <Styled.AppTemplateContainer type="fluid">
      <AppHeader />
      {children}
    </Styled.AppTemplateContainer>
  );
}
