import { Breadcrumb } from '@hexa-ui/components';
import { Link, useLocation } from 'react-router-dom';

export const pathstring = (pathname: string) => {
  let str = pathname.replace('-', ' ').replace('-', ' ').replace(/^\d+(\/edit)?$/, '');

  return str;
};

const createLinks = (index): any => {
  const paths = location.pathname.split('/');
  const links = [];
  paths
    .filter((f) => f !== '' && f != 'banner-cms')
    .map((pathname: string) => {
      const isParam = new RegExp('^[0-9]+$').test(pathname);
      if (!isParam) {
        links.push(pathname);
      } else {
        links.slice(-1);
        links.push(links.pop() + '/' + pathname);
      }
    });

  return links[index];
};

const AutoBreadcrumb = () => {
  const location = useLocation();
  const base = location.pathname.split('/');
  const paths = location.pathname
    .split('/')
    .filter((f) => !new RegExp('^[0-9]+$').test(f) && f !== '' && f != 'banner-cms'&&  f != 'edit');
  const items = [];

  return (
    <div>
      <Breadcrumb.Root>
        <Breadcrumb.HomeItem asChild>
          <Link to="/banner-cms" />
        </Breadcrumb.HomeItem>
        {paths &&
          paths.map((pathname: string, index) => {
            const isParam = new RegExp('^[0-9]+$').test(pathname);
            const last =
              (isParam ? paths.length - 1 : paths.length) == (isParam ? index : index + 1);
            if (isParam) {
              pathname = undefined;
            }
            const links = createLinks(index);
            items.push(links);
            return (
              <Breadcrumb.Item
                isCurrentPage={last}
                data-testid="breadcrumb-item"
                key={index + pathname}
              >
                <Link
                  style={{ textTransform: 'capitalize' }}
                  to={'/' + base[1] + '/' + items.join('/')}
                >
                  {pathstring(pathname)}
                </Link>
              </Breadcrumb.Item>
            );
          })}
      </Breadcrumb.Root>
    </div>
  );
};

export default AutoBreadcrumb;
