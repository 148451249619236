import { Select } from '@hexa-ui/components';
import { Stack } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCountryStore } from 'src/redux/slices/companySlice';
import { useAppSelector } from 'src/redux/store';
import { CountryInterface } from 'src/repository/interfaces/Country/CountryInterface';
import { SERVICE_KEYS, container } from 'src/repository/services/container';

const countryRepository = container.get<CountryInterface>(SERVICE_KEYS.COUNTRY_REPOSITORY);

function SelectCountryStore() {
  const selectedCountry: {
    country: string;
    store: string;
    code: string;
  } = useAppSelector((state) => state.countryStore);
  const [countries, setCountries] = React.useState<Country[]>([]);
  // const [countryId, setCountryId] = React.useState<string>();

  const [companies, setCompanies] = React.useState<Company[]>([]);
  const location = useLocation();
  const { handleSubmit, register, control, watch } = useForm({ mode: 'onChange' });

  const dispatch = useDispatch();

  React.useEffect(() => {
    // setCountries([]);
    //BUSCAR TODOS OS PAISES
    countryRepository.all().then((response) => {
      setCountries(response);

      //BUSCAR TODOS AS EMPRESAS ATRELADAS AO PAIS SELECIONADO
      let tid = response?.find((country: Country) => country.name === selectedCountry.country).tid;
      countryRepository.companies(tid).then((response) => {
        setCompanies(response);
      });
    });
  }, []);


  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'store' && value.store) {
        let formData = value;
        if (!value?.country) {
          formData = {
            ...value,
            country: selectedCountry.country,
          };
        }
        handleChoseCountryStore(formData);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getStore = (countryName: string) => {
    let tid = countries?.find((country: Country) => country.name === countryName)?.tid;
    countryRepository.companies(tid).then((response) => {
      setCompanies(response);
      let formData = {
        store: response[0].name,
        country: countryName,
      };

      handleChoseCountryStore(formData);
    });
  };

  const getCountryCode = (countryName: string) => {
    return countries?.find((country: Country) => country.name === countryName)?.code;
  }

  const handleChoseCountryStore = (formData) => {
    dispatch(setCountryStore({
      ...formData,
      code: getCountryCode(formData.country)
    }));
  };

  return (
    <form onSubmit={handleSubmit(handleChoseCountryStore)}>
      <Stack direction="row" alignItems={'center'} spacing={2}>
        <Controller
          rules={{ required: true }}
          name={'country'}
          control={control}
          render={({ field }) => (
            <Select.Root
              disabled={location.pathname.includes('edit') || location.pathname.includes('create')}
              shape="pill"
              defaultValue={selectedCountry.country}
              value={field.value}
              onChange={(value) => {
                getStore(value);               
                field.onChange(value);
                console.log('FORM DATA', value);
              }}
              placeholder={'Select a country'}
            >
              {countries?.map((country) => (
                <Select.Option {...register('country')} key={country.name} value={country.name}>
                  {country.name}
                </Select.Option>
              ))}
            </Select.Root>
          )}
        />
        <Controller
          rules={{ required: true }}
          name={'store'}
          control={control}
          render={({ field }) => (
            <Select.Root
              shape="pill"
              defaultValue={selectedCountry.store}
              value={selectedCountry.store || field.value}
              onChange={field.onChange}
              placeholder={'Select a store'}
              disabled={
                !companies || companies?.length <= 1
                  ? true
                  : false ||
                    location.pathname.includes('edit') ||
                    location.pathname.includes('create')
              }
            >
              {companies?.map((company: Company) => {
                return (
                  <Select.Option key={company?.name} value={String(company?.name)}>
                    {company?.name}
                  </Select.Option>
                );
              })}
            </Select.Root>
          )}
        />
      </Stack>
    </form>
  );
}

export default React.memo(SelectCountryStore);
