import { FC, lazy } from 'react';
import { layout } from 'src/templates';

const Home = lazy(() => import('@Pages/Home'));
const Lauch = lazy(() => import('@Pages/Launch'));
const LaunchShow = lazy(() => import('@Pages/Launch/LaunchForm/index'));
const LaunchCreateForm = lazy(() => import('@Pages/Launch/LaunchForm'));
// const LauchEditForm = lazy(()=> import('@Pages/Launch/LaunchForm'));

export interface NavProps {
  name: string;
  path: string;
  element: FC;
  template: layout;
  isPrivate: boolean;
}

const PREFIX_PATH = '/banner-cms';

const _routes: Array<NavProps> = [
  {
    name: 'Lauch',
    path: `${PREFIX_PATH}`,
    element: Lauch,
    template: 'LauchTemplate',
    isPrivate: false,
  },
  {
    name: 'Home',
    path: `${PREFIX_PATH}/launch-list`,
    element: Home,
    template: 'AppTemplate',
    isPrivate: true,
  },
  {
    name: 'LaunchForm1',
    path: `${PREFIX_PATH}/launch-list/edit-launch/:id`,
    element: LaunchShow,
    template: 'AppTemplate',
    isPrivate: true,
  },
  {
    name: 'LaunchForm2',
    path: `${PREFIX_PATH}/launch-list/edit-launch/:id/edit`,
    element: LaunchCreateForm,
    template: 'AppTemplate',
    isPrivate: true,
  },
  {
    name: 'LaunchForm3',
    path: `${PREFIX_PATH}/launch-list/create-launch`,
    element: LaunchCreateForm,
    template: 'AppTemplate',
    isPrivate: true,
  },
];

export default _routes;
