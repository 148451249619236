import { createSlice } from '@reduxjs/toolkit';

const companySlice = createSlice({
  name: 'countryStore',
  initialState: null,
  reducers: {
    setCountryStore: (state, action) => {
      return action.payload ? { ...action.payload } : state;
    },
    getCountryStore: (state) => state,
  },
});

export const { setCountryStore, getCountryStore } = companySlice.actions;

export default companySlice.reducer;
