import MessageMap from '../i18n';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
};

export default enUS;
