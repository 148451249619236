// import axios from 'axios';
import { injectable } from 'inversify';
import { CountryInterface } from 'src/repository/interfaces/Country/CountryInterface';
import { api } from './_index';
@injectable()
export default class CountryApiService implements CountryInterface {
  /**
   * Display a listing of the resource.
   */
  all(): Promise<Country[]> {
    return api
      .get('/banner/countries')
      .then((response) => response.data)
      .catch((error) => error.response);
  }
  /**
   * Display a listing companies of the resource.
   */
  companies(countryTID: string): Promise<any> { 
    return api
      .get(`/${countryTID}/stores`)
      .then((response) => response.data)
      .catch((error) => error.response);
  }

  test(): string {
    return 'OK';
  }
}
