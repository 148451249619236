import { Grid, Toast, ToastTypes } from '@hexa-ui/components';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { EnvProvider } from './components';
import GlobalContexts from './context';
import { IntlProvider } from './i18n';
import { persistor, store } from './redux/store';

declare global {
  interface Window {
    toast: (props) => JSX.Element;
  }
}

window.toast = ({ position = 'top-right', ...rest }: ToastTypes) => (
  <Toast.Root {...rest} position={position} />
);

export default function App(props: any) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-bees-banner-cms-mfe',
    seed: 'bees-hub-bees-banner-cms-mfe',
  });

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <EnvProvider env={props}>
          <Toast.Provider duration={3000} swipeDirection="right">
            <StylesProvider generateClassName={generateClassName}>
              <IntlProvider>
                <GlobalContexts>
                  <Grid.Container type="contained" sidebar style={{ display: 'block', scrollBehavior: 'smooth' }}>
                  <Router />
                </Grid.Container>
                </GlobalContexts>
              </IntlProvider>
            </StylesProvider>
          </Toast.Provider>
        </EnvProvider>
      </PersistGate>
    </Provider>
  );
}
