import { useEnvService } from 'admin-portal-shared-services';

export const API_KEY = 'Basic YW1hbmRhLmNvcmFkaW46dkN4VTlNUGlhUnp6OWJt';

const sit_url = 'https://beesbanner.dev-abinbev.acsitefactory.com';
const uat_url = 'https://beesbanner.test-abinbev.acsitefactory.com';
const prod_url = 'https://apidv-banner.ab-inbev.com';

const env = useEnvService().getEnv()

export const API_URL = env === "QA" || env === "SIT" ? sit_url : env === "UAT" ? uat_url : env === "PROD" ? prod_url : uat_url
